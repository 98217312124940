export const useYandexStore = defineStore('yandex', () => {
  const metrikaId = ref<number | null>(null);
  const metrikaInitialized = ref<boolean>(false);

  const initMetrika = () => {
    metrikaInitialized.value = true;
  };

  const setMetrikaId = (id: number) => {
    metrikaId.value = id;
  };

  return {
    metrikaInitialized,
    metrikaId,
    initMetrika,
    setMetrikaId,
  };
});