export default defineAppConfig({
  env: import.meta.env.VITE_ENVIRONMENT,
  release: import.meta.env.VITE_RELEASE,
  api: {
    baseUrl: import.meta.env.VITE_API_BASE_URL,
  },
  echo: {
    env: import.meta.env.VITE_ECHO_ENV,
    host: import.meta.env.VITE_ECHO_HOST,
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
  },
  telegram: {
    bot_link: import.meta.env.VITE_TELEGRAM_BOT_LINK
  },
  splitio: {
    server_key: import.meta.env.VITE_SPLITIO_SERVER_KEY,
    redis: {
      host: import.meta.env.VITE_SPLITIO_REDIS_HOST || 'localhost',
      port: import.meta.env.VITE_SPLITIO_REDIS_PORT || 6379,
      db: import.meta.env.VITE_SPLITIO_REDIS_DB || 0,
      password: import.meta.env.VITE_SPLITIO_REDIS_PASSWORD,
      prefix: import.meta.env.VITE_SPLITIO_REDIS_PREFIX
    }
  },
  yandex: {
    metrika: {
      web: import.meta.env.VITE_YANDEX_METRIKA_WEB_ID,
      mini_app: import.meta.env.VITE_YANDEX_METRIKA_MINI_APP_ID
    }
  }
});