import {initYandexMetrika} from "~/utils/init-yandex-metrika";

interface YandexWindow {
  ym: Function
}

export default defineNuxtPlugin((nuxtApp) => {
  const store = useYandexStore();
  const {metrikaInitialized, metrikaId} = storeToRefs(store);

  const init = () => {
    if (metrikaInitialized.value) {
      return;
    }

    initYandexMetrika(metrikaId.value!);

    store.initMetrika();
  };

  nuxtApp.hook('page:finish', () => {
    if (!metrikaId.value) {
      return;
    }

    init();

    (window as any as YandexWindow).ym(metrikaId.value, 'hit');
  });
});